.banner {
  display: block;
  box-sizing: border-box;
  height: auto;

  /* background-image: linear-gradient(
    to right,
    #2a6fa7,
    #0096c7,
    #00bbb8,
    #00d97d,
    #a8eb12
  ); */
  background-image: linear-gradient(
    to right,
    #326ec8,
    #009de5,
    #00c3ce,
    #00e197,
    #c7f363
  );
  clip-path: ellipse(120% 100% at 50.31% 0%);
}

.typed string {
  font-size: 500;
}
