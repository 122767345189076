@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 600px) {
  .hide-after-600 {
    display: none;
  }
}
body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hover {
  transition: transform 0.4s ease, padding 0.4s ease, width 0.4s ease,
    height 0.3s ease;
  padding: 10px;
  /* width: 230px;
  height: 150px; */
}

.hover:hover {
  transform: scale(1.1); /* Slightly increase the size */
  padding: 20px;
  /* width: 230px;
  height: 150px; */
  /* background-image: linear-gradient(
    to right,
    #96faff,
    #8afce9,
    #93fccb,
    #adf9a6,
    #d0f282
  ); */
  /* color: white; */
}

.bgGradient {
  background: linear-gradient(
    90.25deg,
    hsla(0, 0%, 98.8%, 0.35) 5.68%,
    hsla(0, 0%, 98.8%, 0.3) 143.91%
  );
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  backdrop-filter: blur(20px);
}
