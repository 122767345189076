.footer {
  background-image: linear-gradient(
    to right,
    #326ec8,
    #009de5,
    #00c3ce,
    #00e197,
    #c7f363
  );
  padding: 40px;
}
